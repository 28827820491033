import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable ,of} from 'rxjs';
import { BACKEND_ENDPOINTS } from '@routes';
 
import { delay } from 'rxjs/operators';
import { SettigSignal } from '@interfaces';
@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {
  isSkelton = signal<boolean>(false);
 
  constructor(private readonly http: HttpClient) { }

  fetchSettings(parems:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_SETTINGS()}?type=${parems}`);
  }
  addSettings(data:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_SETTINGS()}`,data);
  }
  
  deleteSettings(data:any): Observable<any>{
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_SETTINGS()}`,data);
  }

  fetchCashAdvances(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_CASH_ADVANCE_SETTINGS()}`);
  }
    
  deleteCashAdvancesSettings(data:any): Observable<any>{
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_CASH_ADVANCE_SETTINGS()}`,data);
  }
  fetchWelfares(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_WELFARE_SETTINGS()}`);
  }
  passSignal<T extends keyof SettigSignal>(
    property: T,
    value: SettigSignal[T] extends object ? Partial<SettigSignal[T]> : SettigSignal[T],
    settingSignal:any
  ) {
    settingSignal.update((state: SettigSignal) => ({
      ...state,
      [property]:
        typeof state[property] === "object" && !Array.isArray(state[property])
          ? { ...(state[property] as object), ...(value as object) }
          : value,
    }));
  }

  addCashAdvanceSettings(data:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_CASH_ADVANCE_SETTINGS()}`,data);
  }

  addWelfaresSettings(data:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_WELFARE_SETTINGS()}`,data);
  }
  deleteWelfaresSettings(data:any): Observable<any>{
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_WELFARE_SETTINGS()}`,data);
  }

  fetchExpenses(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_EXPENSE_SETTINGS()}`);
  }
  updateExpenseSettings(data:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.SETTINGS.ROUTE_UPDATE_EXPENSE_SETTINGS()}`,data);
  }
 
}

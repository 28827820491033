<div class="exit-popup">
  <snl-modal class="model-reject" (modalClose)="closeModal()" [type]="'medium'" [open]="confirmationModalOpen()" [settings]="{backdropClick:false,showCloseIcon:true}">
      <div slot="heading">{{title}}</div>  
      <div slot="modalContent">
          <span class="body-b1 color__primary"></span>
          <div class="body-b1 color__primary" [innerHTML]="message"></div>    
      </div>
      <div slot="modalFooter">
          <snl-button size="large" type="primary" (buttonClick)="proceed()">{{button}}</snl-button>
          <snl-button size="large" type="secondary" (buttonClick)="closeModal()">Cancel</snl-button>
      </div>
  </snl-modal>
</div>
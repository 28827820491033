export enum ModuleType {
    ManageApprovals = 'approvals',
    ManageCashAccountManagement = 'cash-account-management',
    ManageSettings = 'settings',
    ManageHistory = 'history',
    ManageForms = 'forms',
    ManageNewsAndAnnouncements = "news-and-announcements",
}

export enum UserAccessMapping {
  ANNOUNCEMENT = "news-and-announcements",
  APPROVALS = "approvals",
  CAM = "cash-account-management",
  FORMS = "forms",
  HISTORY = "history"
}

export enum ApprovalsPath {
  PROFILE_DETAILS = 'details/profile',
  DOCUMENT_DETAILS = 'details/documents',
  BENEFICIARIES_DETAILS = 'details/beneficiaries',
  CREATE_NEWS = 'create-news',
  UPDATE_NEWS = 'edit-news',
  NEWS_DETAILS = 'details',
  EXPENSES_DETAILS = 'details/expenses',
  CASH_ADVANCES_DETAILS = 'details/cashadvances',
}
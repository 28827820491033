import { Injectable, signal } from '@angular/core';
import { SnlDropdowns, UserAccess } from '@interfaces';
import { BehaviorSubject } from 'rxjs';
import { INPUT_ERROR_MESSAGE } from '../../constants/stolt-constant';


@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {
   snlDropdownData = signal<SnlDropdowns>({
    countriesData:[],
    relationshipsData: [],
    maritalStatusData:[],
    nationalityData: [],
   })
  publishExits = signal({
    title: 'Unsaved changes',
    message: 'Are you sure you want to leave this page? Changes you made will not be saved.',
    button: 'Leave',
    payload: {}
  })
  private readonly role$: BehaviorSubject<any> = new BehaviorSubject(0);
  public UserAccessObj: any;
  private UserAccessSubject = new BehaviorSubject<any>(null);
  
  private readonly newsAnnouncementDetail$: BehaviorSubject<any> = new BehaviorSubject(0);
  private readonly formsDetail$: BehaviorSubject<any> = new BehaviorSubject(0);
  private readonly exitConfirmation$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly exitConfirmationOpenModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly exitConfirmUrl$: BehaviorSubject<string> = new BehaviorSubject('');
  private readonly ckUploadImage$: BehaviorSubject<any> = new BehaviorSubject('');

  constructor() { 
    // This is intentional
  }

  setUserRole(role: any) {
    this.role$.next(role);
  }

  getUserRole() {
    return this.role$.asObservable();
  }

  setFormsDetail(detail: any) {
    this.formsDetail$.next(detail);
  }

  getFormsDetail() {
    return this.formsDetail$.asObservable();
  }
  setNewsAnnouncementDetail(detail: any) {
    this.newsAnnouncementDetail$.next(detail);
  }

  getNewsAnnouncementDetail() {
    return this.newsAnnouncementDetail$.asObservable();
  }

  setExitConfirmation(confirmation: boolean) {
    this.exitConfirmation$.next(confirmation);
  }

  getExitConfirmation() {
    return this.exitConfirmation$.asObservable();
  }

  setExitConfirmationOpenModal(modal: boolean) {
    this.exitConfirmationOpenModal$.next(modal);
  }

  getExitConfirmationOpenModal() {
    return this.exitConfirmationOpenModal$.asObservable();
  }

  setExitConfirmUrl(url: string) {
    this.exitConfirmUrl$.next(url);
  }

  getExitConfirmUrl() {
    return this.exitConfirmUrl$.asObservable();
  }

  setCkUploadImage(image: any) {
    this.ckUploadImage$.next(image);
  }

  getCkUploadImage() {
    return this.ckUploadImage$.asObservable();
  }

  dropdownOptions(data: any, id: string, name: string, toUpperCase = false) {
    let options: { id: any; name: any; }[] = [];
    data?.forEach((element: any) => {
      if (element[id] && element[name]) {
        options.push({ id: element[id], name: (toUpperCase) ? element[name].toUpperCase() : element[name] });
      }

    });
    return options;
  }

  public showInputError(field: string, type: string, data: any) {
    if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('pattern')) {
      return this.errorMsg(field, type);
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('maxlength')) {
      return this.errorMsg(field, 'maxlength');
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('max')) {
      return this.errorMsg(field, 'max');
    }
    return '';
  }

  errorMsg(field: string, type: string) {
    if (type == 'string') {
      return INPUT_ERROR_MESSAGE.CHARACTOR_ONLY;
    }
    else if (type == 'number') {
      return INPUT_ERROR_MESSAGE.NUMBER_ONLY;
    }
    else if (type == 'special') {
      return INPUT_ERROR_MESSAGE.SPECIAL;
    }
    else if (type == 'maxlength') {
      return INPUT_ERROR_MESSAGE.MAXLENGTH;
    }
    else if (type == 'max') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_MAX;
    }
    else if (type == 'percentage') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_DATA_FORMET;
    }
    else {
      return '';
    }
  } 

  setUserAccessData(value: any) {
    this.UserAccessObj = value;
    this.UserAccessSubject.next(value);
  }
  getUserAccessData() {
    return this.UserAccessSubject;
  }

  public getChangedFields(initialValue: any, currentValue: any): string[] {
    const changedFields: string[] = [];
    Object.keys(initialValue).forEach((key) => {
      const originalValue = this.normalizeFieldValue(initialValue[key]);
      const currentValueField = this.normalizeFieldValue(currentValue[key]);
      if (Array.isArray(originalValue) && Array.isArray(currentValueField)) {
        if (originalValue.length !== currentValueField.length ||
            !originalValue.every((value, index) => value === currentValueField[index])) {
          changedFields.push(key);
        }
      } else if (originalValue !== currentValueField) {
        changedFields.push(key);
      }
    });
    return changedFields;
  }

  compareObjects(original: any, updated: any): boolean {
    return JSON.stringify(original) === JSON.stringify(updated);
  }

  public normalizeFieldValue(value: any): any {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }
    return value;
  }

}
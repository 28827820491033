import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BACKEND_ENDPOINTS } from '@routes';
import { ApprovedBeneficariesPayload } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesApiService {
  
  constructor(private readonly http: HttpClient) { }


  fetchBeneficiariesDetails(id:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.BENEFICIARIES_DETAILS(id)}`);
  }

   approvedBeneficiariesData(id:string,data:ApprovedBeneficariesPayload): Observable<any>{
      return this.http.put(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.BENEFICIARIES_APPROVAL(id)}`,data);
    }
 
}

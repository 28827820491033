import { environment } from "src/environments/environment";
import { CrewSelfService } from "../crew-self/crew-self.service";
import { MsalService } from "@azure/msal-angular";
import { ToasterService } from '@services';
import { NOTIFICATION_MESSAGE } from "@constants";

export class UploadAdapter {
    loader: any;
    xhr: any;
    crewSelfService: CrewSelfService;
    msalService: MsalService;
    toastService: ToasterService;
    constructor( loader: any,crewSelfService: CrewSelfService,msalService: MsalService,toastService: ToasterService) {
        this.loader = loader;
        this.crewSelfService = crewSelfService;
        this.msalService = msalService;
        this.toastService = toastService;
    }
    upload() {
        return this.loader.file
            .then( (file: File) => new Promise(( resolve, reject ) => {
                this._initRequest().then(() => {
                    this._initListeners( resolve, reject, file );
                    this._sendRequest( file );
                });
            } ) );
    }
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }
    async _initRequest() {
        let token = await this.msalService.instance.acquireTokenSilent({scopes: [environment.AAD_USER_API_SCOPE]});
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open( 'POST', `${environment.BACKEND_API_BASE_URL}/uploadImages`, true ); // TODO change the URL
        xhr.responseType = 'json';
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader('Authorization','Bearer ' + token.accessToken);
       
    }
    _initListeners( resolve: (response: any) => void, reject: (error?: any) => void, file: File ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;
        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;
            if ( !response || response.status === 'error') {
                this.toastService.openToast('Error', NOTIFICATION_MESSAGE.CONTENT_FILE_SIZE_EXCEEDS);
                return reject( '' );
            }
            else{
                this.crewSelfService.setCkUploadImage(response?.data.url);
            }
            resolve( {
                default: response.data.url
            } );
        } );
        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', (evt: ProgressEvent) => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }
    _sendRequest( file: File ) {
        const data = new FormData();
        data.append( 'upload', file );
        this.xhr.send( data );
    }
}
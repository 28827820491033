import { Component, Output, EventEmitter, Input, model, effect, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ButtonModule, ModalModule } from 'snl-complib';
import { CrewSelfService } from '../../services/crew-self/crew-self.service';
import { Router } from '@angular/router';
import { FormApiService } from '../../services/form/form-api.service';
import { ToasterService } from '@services';

@Component({
  selector: 'app-exit-confirmation-modal',
  imports: [ButtonModule,ModalModule],
  templateUrl: './exit-confirmation-modal.component.html',
  styleUrl: './exit-confirmation-modal.component.scss'
})
export class ExitConfirmationModalComponent {
  @Output() confirmExit = new EventEmitter<boolean>();
  @Input() title: string = 'Unsaved changes';
  @Input() message: string = 'Are you sure you want to leave this page? Changes you made will not be saved.';
  @Input() button: string = 'Leave';
  @Input() payload: any={};
  confirmationModalOpen = model<boolean>(false);
  formType:any;
  public exitConfirmUrl = ''
  subscription: Subscription;

  constructor(
    private readonly crewSelfService: CrewSelfService,
    private readonly formApiServices:FormApiService,
    private readonly toasterService: ToasterService,
    private readonly router: Router) {
    this.subscription = this.crewSelfService.getExitConfirmationOpenModal().subscribe((event) => {
      this.confirmationModalOpen.set(event);
    });
    this.subscription = this.crewSelfService.getExitConfirmUrl().subscribe((event) => {
      this.exitConfirmUrl = event;
    });
    effect(()=>{
      this.title = this.crewSelfService.publishExits().title;
      this.message = this.crewSelfService.publishExits().message;
      this.button = this.crewSelfService.publishExits().button;
      this.payload= this.crewSelfService.publishExits().payload;
    })
  }

  closeModal() {
    this.confirmationModalOpen.set(false);
    this.crewSelfService.setExitConfirmation(false);
    document.body.style.overflow = "auto";
  }

  proceed() {
    if(this.button=='Publish'){
      this.publishChanges();
      this.crewSelfService.setExitConfirmation(true);
      this.confirmationModalOpen.set(false);
      this.router.navigate([this.exitConfirmUrl]);
    }else{
      this.crewSelfService.setExitConfirmation(true);
      this.confirmationModalOpen.set(false);
      this.router.navigate([this.exitConfirmUrl]);
    }
    
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  publishChanges(){
    this.formType = this.payload.FORM_TYPE;
    this.formApiServices.submitFormDetail(this.payload).subscribe({
			next: (response) => {
        if (response.status == 'success') {
          const msg = this.formType == 'FEEDBACK'? 'The 360 feedback form is Published.': 'The survey form is Published.';
          this.toasterService.openToast('Success', msg);
				}
			},
			error: (error) => {
        this.toasterService.openToast('Error', error.error.message);
			},
			complete: () => {
			}
		});
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoltApiService {
  
  constructor(
    private readonly http: HttpClient) {}

  fetchRanks(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/ranks`);
  }

  fetchCountryList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/countries`);
  }
  fetchRelationshipList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/relationships`);
  }
  fetchMaritalStatusList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/marital-status`);
  }
  fetchBankList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/bank-list`);
  }
    
  fetchVessels(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/vessels`);
  }

  getUserDetail(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/user-access`);
  }

  uploadImage(imageBase64: any,endpoint:string, name:string,type?: string, ): Observable<any> {
    let imageBlob:any;
    if(type==="image"){
      imageBlob = this.dataURItoBlob(imageBase64 as string);
    }
    else{
      imageBlob = imageBase64
    }
    const formData = new FormData();
    formData.append('file', imageBlob, name); // You can change the file name
    return  this.http.post(`${environment.BACKEND_API_BASE_URL}/${endpoint}`, formData,{headers: new HttpHeaders({})})
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin, map } from 'rxjs';
import { ApprovalsService } from '@services';
import { DocumentsPdfDetails, ImageFile } from '@interfaces';
@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {
  
  constructor(private readonly http: HttpClient,private readonly approvalServices:ApprovalsService) { }


  fetchDocumentDetail(id:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/documents/${id}`);
  }

  fetchDocumentDetailByType(id: string, docType: string): Observable<any> {
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/document-approval/${id}`, {
      params: { docType: docType }
    });
  }
  approveDocumentDetail(id: any,formData:any): Observable<any> {
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/document-approval/${id}`, formData );
  }

  // addDocumentData(postData:any): Observable<any>{
  //   return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents`, postData);
  // }



  fetchCategoryType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchFormElementsForType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchDocumentList(query_string:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/documents?${query_string}`);
  }

  addDocumentData(postData:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/documents`, postData);
  }


  deleteDocument(id: string) {
    return this.http.delete(`${environment.BACKEND_API_BASE_URL}/documents/${id}`);
  }

  public getChangedFields(initialValue: any, currentValue: any): string[] {
    const changedFields: string[] = [];
    Object.keys(initialValue).forEach((key) => {
      const originalValue = this.normalizeFieldValue(initialValue[key]);
      const currentValueField = this.normalizeFieldValue(currentValue[key]);
      if (Array.isArray(originalValue) && Array.isArray(currentValueField)) {
        if (originalValue.length !== currentValueField.length ||
            !originalValue.every((value, index) => value === currentValueField[index])) {
          changedFields.push(key);
        }
      } else if (originalValue !== currentValueField) {
        changedFields.push(key);
      }
    });
    return changedFields;
  }

  public normalizeFieldValue(value: any): any {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }
    return value;
  }

    downloadZip(attachmentfiles:ImageFile[]) {
      console.log("attachmentfiles",attachmentfiles)
      const zip = new JSZip();
      const getFileContent = (file: { fileName: string, name: string }) =>
        this.approvalServices.getPDFContent({ fileName: file.fileName }).pipe(
          map((response: DocumentsPdfDetails) => ({
            name: file.name,
            content: response.data.data
          }))
        );
      const fileObservables = attachmentfiles?.map(getFileContent);
      forkJoin(fileObservables).subscribe({
        next: (results) => {
          this.addFilesToZip(results, zip);
          this.generateZipFile(zip);
        },
        error: (err) => {
          this.handleError(err);
        }
      });
    }
    downloadZipNew(attachmentfiles: ImageFile[]) {
      const arrFiles = attachmentfiles?.filter((file:ImageFile)=> !file.isDelete );
      const zip = new JSZip();
      const getFileContent = (data: any): Observable<{ name: string; content: any }> => {
        const queryParams = {
          file: data?.fileName,
          source: data?.type  || 'AZURE',
          originalFileName: data?.originalFileName
        };
    
        return this.approvalServices.fetchDocument(queryParams).pipe(
          map((response: any) => ({
            name: data.originalFileName,  // Corrected property access
            content: response
          }))
        );
      };
    
      const fileObservables = arrFiles?.map(getFileContent);
      if (!fileObservables || fileObservables.length === 0) {
        console.error("No files to download");
        return;
      }
    
      forkJoin(fileObservables).subscribe({
        next: (results: any) => {
          this.addFilesToZip(results, zip);
          this.generateZipFile(zip);
        },
        error: (err) => {
          console.error("Error while downloading files:", err);
          this.handleError(err);
        }
      });
    }
    
    private addFilesToZip(files: { name: string, content: ArrayBuffer | Blob }[], zip: JSZip) {
      files.forEach(({ name, content }) => {
        zip.file(name, content);
      });
    }
  
    private generateZipFile(zip: JSZip) {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'Download-attachements.zip');
      }).catch(err => {
        console.error('Error generating ZIP file:', err);
      });
    }
    private handleError(error: any) {
      console.error('Error while fetching or processing files for download all attachements:', error);
    }
  
}


export const DOCUMENTS_STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
});

export const APPROVALS_STATE = Object.freeze({
  PROFILE: 'Profile',
  DOCUMENTS: 'Documents',
  EXPENSES: 'Expenses',
  CASH_ADVANCES: 'Cash advances',
  BENEFICIARIES: 'Beneficiaries',
 
});

export const NEWSANDANNOUNCEMENTS_STATE = Object.freeze({
  INPROGRESS: 'In progress',
  EXPIRED: 'Expired',
  
 
});
export const SELECTRECIPIENTS_STATE = Object.freeze({
  CREW: 'Crew',
  SHIPS: 'Ships',
});

export const FORMS_STATE = Object.freeze({
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
});

export const FORMS_TABS = Object.freeze([
  { key: 'DRAFT', value: 'Draft'},
  { key: 'ACTIVE', value: 'Active' },
  { key: 'INACTIVE', value: 'Inactive' },
]);


export const FORMS_VIEW_STATE = Object.freeze({
  WEB: 'Web',
  MOBILE: 'Mobile',
});

export const FORMS_VIEW_TABS = Object.freeze([
  { key: 'WEB', value: 'Web'},
  { key: 'MOBILE', value: 'Mobile' },
]);

export const FORMS_DETAIL_VIEW_STATE = Object.freeze({
  FORMDETAILS: 'Form details',
  CONFIGURE: 'Configure',
  RESULTS: 'Results',
});

export const FORMS_DETAIL_VIEW_TABS = Object.freeze([
  { key: 'FORMDETAILS', value: 'Form details'},
  { key: 'CONFIGURE', value: 'Configure' },
  { key: 'RESULTS', value: 'Results' },
]);

export const APPROVALS_TABS = Object.freeze([
    { key: 'PROFILE', value: 'Profile'},
    { key: 'DOCUMENTS', value: 'Documents' },
    { key: 'EXPENSES', value: 'Expenses' },
    { key: 'CASH_ADVANCES', value: 'Cash advances' },
    { key: 'BENEFICIARIES', value: 'Beneficiaries' }
]);

export const FORM_TABS = Object.freeze([
  { key: 'DRAFT', value: 'Draft'},
  { key: 'ACTIVE', value: 'Active' },
  { key: 'INACTIVE', value: 'Inactive' },
]);
export const NEWSANDANNOUNCEMENTS_TABS = Object.freeze([
    { key: 'INPROGRESS', value: 'In progress', count:0},
    { key: 'EXPIRED', value: 'Expired', count:0 },
    
]);
export const SELECTRECIPIENTS_TABS = Object.freeze([
    { key: 'CREW', value: 'Crew', count:0},
    { key: 'SHIPS', value: 'Ships', count:0 },
    
]);
export const SETTINGS_TABS = Object.freeze([
  { key: 'RANKS', value: 'Ranks', checkAccess: false },
  { key: 'SHIPS', value: 'Ships', checkAccess: false },
  { key: 'EXPENSES', value: 'Expenses', checkAccess: true },
  { key: 'CASH_ADVANCES', value: 'Cash advance', checkAccess: true },
  { key: 'WELFARE_CONTRIBUTION', value: 'Welfare contribution', checkAccess: true }
]);

export const SETTINGS_STATE = Object.freeze({
  RANKS: 'Ranks',
  SHIPS: 'Ships',
  EXPENSES: 'Expenses',
  CASH_ADVANCES: 'Cash advance',
  WELFARE_CONTRIBUTION: 'Welfare contribution',
});




export const APPROVALS_ROLES_MODULES = Object.freeze({
  ROLE_1 :['PROFILE','DOCUMENTS','EXPENSES','CASH_ADVANCES','BENEFICIARIES'],
  ROLE_2 :['PROFILE'],
  ROLE_3 :['DOCUMENTS'],
  ROLE_4 :['BENEFICIARIES'],
  ROLE_5 :['EXPENSES'],
  ROLE_6 :['CASH_ADVANCES'],
});

export const SETTINGS_ROLES_MODULES = Object.freeze({
  ROLE_1 :['RANKS','SHIPS','WELFARE_CONTRIBUTION','EXPENSES','CASH_ADVANCES',],
  ROLE_2 :['RANKS','SHIPS',],
});

export const CURRENCY_LIST = Object.freeze([
  { id: 'USD', value: 'USD', name: 'USD', symbol: '$' },
  { id: 'PHP', value: 'PHP', name: 'PHP', symbol: '₱' }
]);


export const USER_BENEFICIARY = Object.freeze({
  FILIPINO_POOL_CODE:[13009],
  FILIPINO_ALLOTMENT_LIMIT:3,
  OTHER_ALLOTMENT_LIMIT:2
});
 
export const STOLT_CONST = Object.freeze({
  SUCCESS_STATUS: 'success',

});

export const NOTIFICATION_MESSAGE = Object.freeze({
  FILE_SIZE_EXCEEDS: 'The file size exceeds the maximum allowed limit of 5MB. Please upload a smaller file.',
  THUMBNAIL_SIZE_EXCEEDS_NEWS_ANNOUNCEMENT: 'The file size exceeds the maximum allowed limit of 2MB. Please upload a smaller file.',
  FILE_SUPPORT_FORMET: 'Supported file format only JPEG and PNG.',
  ATTACHMENT_SUPPORT_FORMET: 'Supported file format only JPEG, PDF and PNG.',
  DOCUMENT_LIMIT_EXCEED: 'Only 3 files are allowed.',
  ALLOTMENT_LIMIT_EXCEED: 'Only 3 beneficiaries are allowed.',
  BENEFICIARY_SAVED_SUCCESS:'Your beneficiary was saved successfully.',
  BENEFICIARY_SUBMITTED_SUCCESS:"Your beneficiaries were submitted for approval.",
  BENEFICIARY_ONE_SUBMITTED_SUCCESS:"Your beneficiary was submitted for approval.",
  FILIPINO_ALLOTMENT_LIMIT_EXCEED: 'Only 3 allotment beneficiaries are allowed.',
  BOW_LIMIT_EXCEED: 'Only 1 BOW beneficiary are allowed.',
  THUMBNAIL_LIMIT_EXCEED: 'Only 1 thumbnail is allowed.',
  ATTACHMENT_LIMIT_EXCEED: 'Only 5 files are allowed.',
  DRAFT:"Your details were saved successfully.",
  CONTENT_FILE_SIZE_EXCEEDS: 'The file size exceeds the maximum allowed limit of 7MB. Please upload a smaller file.',
  PENDING_APPROVAL:"Your details were saved and submitted for approval.",
});





export const USER_STATUS = Object.freeze({
  APPROVED: 'Approved',
  PENDING_APPROVAL: 'Pending approval',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  REQUESTE_FOR_DELETION:'Submitted for deletion',
  MARKED_FOR_DELETION:'Marked for deletion'
})

export const DEFAULT_ACCCOUNT = Object.freeze({
   BANK: ['MARTRUST CORPORATION LIMITED','MARITIME PAYMENT SOLUTIONS LLC'],
   ACCOUNT:'123454321'
});

export const SUCCESS_MESSAGE = Object.freeze({
  PROFILE:"profile changes has been approved successfully.",
  FAMILY:"family changes has been approved successfully.",
  PROFILEFAMILY:"profile & family changes has been approved successfully.",
  DOCUMENT:"document changes has been approved successfully.",
  BENEFICIARY:"beneficiary has been approved successfully.",
  CASH_ADVANCES:"cash advance has been approved successfully.",
});

export const REJECTED_MESSAGE = Object.freeze({
  PROFILE:"profile has been rejected successfully.",
  FAMILY:"family has been rejected successfully.",
  PROFILEFAMILY:"profile & family has been rejected successfully.",
  DOCUMENT:"document has been rejected successfully.",
  BENEFICIARY:"beneficiary has been rejected successfully.",
  CASH_ADVANCES:"cash advance has been rejected successfully.",
});

export const BTN_TEXT = Object.freeze({
  SAVE_AS_DRAFT: 'Save as draft',
  CANCEL_DRAFT: 'Cancel draft',
  UPDATE_DRAFT: 'Update draft',
  SAVE_AND_SUBMIT: 'Save as Submit',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
});

export const USER_STATUS_LABEL = Object.freeze([
  { status: 'Approved', label: 'Approved on', filed: 'APPROVED_ON', edit: true},
  { status: 'Draft', label: '',edit: false ,filed: 'REQUESTED_ON' },
  { status: 'Pending approval', label: 'Submitted on',edit: false , filed: 'REQUESTED_ON'},
  { status: 'Rejected', label: 'Rejected on',edit: true, filed: 'REQUESTED_ON' },
  { status: 'Submitted for deletion', label: 'Submitted on',edit: false, filed: 'REQUESTED_ON' },
  { status: 'Marked for deletion', label: 'Marked on',edit: true, filed: 'REQUESTED_ON' }
]);


export const INPUT_ERROR_MESSAGE = Object.freeze({
  CHARACTOR_ONLY: 'Special characters and numbers are not allowed.',
  NUMBER_ONLY: 'Only numbers are allowed.',
  SPECIAL: 'Special characters are not allowed.',
  MAXLENGTH:'You have reached the maximum characters limit for this field.',
  MAX:'You have reached the maximum limit for this field.',
  PERCENTAGE_DATA_FORMET:'Only 2 decimals are allowed.',
  PERCENTAGE_MAX:'Please enter a value between 0% and 100%.',
  SETTING_AMOUNT_FORMET:'Only 4 numbers are allowed.',
  MODIFY_AMOUNT_FORMET:'Only 5 numbers and 2 decimals are allowed.',
  ACCCOM_DAYS:'Only 3 numbers are allowed.',
  KILLOMETER:'Only 4 numbers and 2 decimals are allowed.',
})


export const DOCUMENT_CATEGORY_TYPE = {
  TRAVEL: "TRAVEL",
  MEDICAL: "MEDICAL",
  LICENSES: "LICENSES/CONTRACTS",
  TRAINING: "TRAINING",
};

export const DOCUMENT_CATEGORY_TYPE_DATA = [
  {
    id: "TRAVEL",
    name: "TRAVEL"
  },
  {
    id: "MEDICAL",
    name: "MEDICAL"
  },
  {
    id: "LICENSES",
    name: "LICENSES/CONTRACTS"
  },
  {
    id: "TRAINING",
    name: "TRAINING"
  }
];

export const EXPENSES_CATEGORY_TYPE = {
  MUSTERING: "Sign on / Sign off",
  MEDICAL_SAFETY: "Medical & Safety",
  TRAINING: "Training",
  MEDICAL_CLAIM: "Medical Claim",
};

export const EXPENSES_CATEGORY_TYPE_DATA = [
  {
    id: "MUSTERING",
    name: "Sign on / Sign off"
  },
  {
    id: "MEDICAL_SAFETY",
    name: "Medical & Safety"
  },
  {
    id: "TRAINING",
    name: "Training"
  },
  {
    id: "MEDICAL_CLAIM",
    name: "Medical Claim"
  }
];


export const MEDICAL_FIT_UNFIT_STATUS = {
  "FIT WITHOUT RESTRICTIONS": "FIT WITHOUT RESTRICTIONS",
  "UNFIT": "UNFIT",
  "FIT WITH RESTRICTIONS": "FIT WITH RESTRICTIONS",
  "NOT APPLICABLE": "NOT APPLICABLE"
};

export const MEDICAL_FIT_UNFIT_STATUS_DATA = [
  {
    id: "FIT WITHOUT RESTRICTIONS",
    name: "FIT WITHOUT RESTRICTIONS"
  },
  {
    id: "UNFIT",
    name: "UNFIT"
  },
  {
    id: "FIT WITH RESTRICTIONS",
    name: "FIT WITH RESTRICTIONS"
  },
  {
    id: "NOT APPLICABLE",
    name: "NOT APPLICABLE"
  }
];


export const MEDICAL_NEG_POS_STATUS = {
  NEGATIVE: "NEGATIVE",
  POSITIVE: "POSITIVE",
};

export const MEDICAL_NEG_POS_STATUS_DATA = [
  {
    id: "NEGATIVE",
    name: "NEGATIVE"
  },
  {
    id: "POSITIVE",
    name: "POSITIVE"
  }
];

export const TRAVEL_DOC_TYPE = {
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  VACCINATION: "VACC",
  SEAMANBOOK: "SBOOK"
}

export const TRAINING_INT_EXT_STATUS = {
  N: "INTERNAL",
  Y: "EXTERNAL"
};

export const TRAINING_INT_EXT_STATUS_DATA = [
  {
    id: "N",
    name: "INTERNAL"
  },
  {
    id: "Y",
    name: "EXTERNAL"
  }
];

export const VISA_ENTRY_TYPE = {
  "1 Entry": "1 Entry",
  "2 Entries": "2 Entries",
  "Multiple Entries": "Multiple Entries",
}

export const VISA_ENTRY_TYPE_DATA = [
  {
    id: "1 Entry",
    name: "1 Entry"
  },
  {
    id: "2 Entries",
    name: "2 Entries"
  },
  {
    id: "Multiple Entries",
    name: "Multiple Entries"
  }
];

export const VISA_DURATION_TYPE = {
  "60 Days": "60 Days",
  "90 Days": "90 Days",
  "120 Days": "120 Days",
  "180 Days": "180 Days"
}

export const VISA_DURATION_TYPE_DATA = [
  {
    id: "60 Days",
    name: "60 Days"
  },
  {
    id: "90 Days",
    name: "90 Days"
  },
  {
    id: "120 Days",
    name: "120 Days"
  },
  {
    id: "180 Days",
    name: "180 Days"
  }
];
export const VISA_DURATION_MCV_TYPE = {
  "3 years": "3 years"
 }


 export const VISA_DURATION_MCV_TYPE_DATA = [
  {
    id: "3 years",
    name: "3 years"
  }
];


export const DOCUMENT_API_CONSTANT = {
  MEDICAL: "medical-type",
  TRAINING: "training-type",
  TRAVEL: "travel-list",
  LICENSES: "license-type",
  FALGSTATE: ""
}
interface DocumentCategoryDetails {
  key_code: string;
  key_desc: string;
}

export const DOCUMENT_CATEGORY_KEY_MAP: {
  [key: string]: DocumentCategoryDetails;
} = {
  MEDICAL: {
    key_code: 'MEDICALTYPE_CODE',
    key_desc: 'DESCRIPTION'
  },
  TRAINING: {
    key_code: 'TRAINING_CODE',
    key_desc: 'TRAINING_DESCRIPTION'
  },
  TRAVEL: {
    key_code: 'DOCCATEGORY_CODE',
    key_desc: 'DESCRIPTION'
  },
  LICENSES: {
    key_code: 'LICENSE_CODE',
    key_desc: 'LICENSE_DESCRIPTION'
  }
} as const;

// Define an interface for Document Fields
interface DocumentField {
  label: string;
  key: string;
  type: string;
  split: boolean;
  postion?: number; // Optional, since not all fields have this property
}

// Define an interface for Categories
type DocumentCategory = 'TRAVEL' | 'MEDICAL' | 'LICENSES' | 'TRAINING';


type DocumentFieldType = {
  label: string;
  key: string;
  type: string;
  options?: any;
  split: boolean;
  formControlName?: string,
  position?: number;
  condition_on?: string;
  optionKey?: string;
  exclude?: string[];
  excludeKey?: string;
  required?: boolean// Add optional properties if they exist
};

type DocumentFieldsMap = {
  [key in DocumentCategory]: {
    [key: string]: DocumentFieldType[];
  };
};

// Define the DOCUMENT_FIELDS constant with the appropriate type

export const ADD_DOCUMENT_FIELDS: any = {
  TRAVEL: {
    PASS: [
      { label: 'Passport number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true, key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SBOOK: [
      { label: "Seaman's document type", required: true, key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Document number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true,key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
     
    ],
    VISA: [
      { label: 'Visa type', key: 'SUB_TYPE_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Visa entry', key: 'VISA_ENTRY', type: "select",options:VISA_ENTRY_TYPE_DATA, split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER','VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa duration', key: 'VISA_DURATION', options:[], type: "select-child", split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER', 'VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa number', key: 'DOC_NO',required: true, type: "string", split: false },
      { label: 'Visa country', key: 'COUNTRY_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued country', key: 'VISADOC_COUNTRY',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
    ],
    VACC: [
      { label: "Vaccination type", required: true,key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Comments', key: 'COMMENTS',required: false, type: "string", split: true, exclude: ['VACCYF'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE', required: true,type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  MEDICAL: {
    PREEMPL: [
      { label: 'Fit/Unfit',required: true, key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS_DATA, split: true },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }
    ],
    DRUGALCO: [
      { label: 'Positve Negative',required: true, key: 'COMMENTS', type: "select", options: MEDICAL_NEG_POS_STATUS_DATA, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SUPPMED: [
      { label: 'Fit/Unfit', required: true,key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS_DATA, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }

    ]
  },
  LICENSES: {
    FORM: [
      { label: 'Number', required: true,key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued at',required: true, key: 'ISSUE_AT', type: "select-child", options: [], split: false },
      { label: 'Issued by (optional)', key: 'ISSUE_BY', type: "string", split: false },
      { label: 'Issued date',required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  TRAINING: {
    FORM: [
      { label: 'Number',required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Start date',required: true, key: 'START_DATE', type: "date", split: false },
      { label: 'End date',required: true, key: 'END_DATE', type: "date", split: false },
      { label: 'Expiry date',required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
      { label: 'Internal or external',required: true, key: 'INTERNAL', type: "select", options: TRAINING_INT_EXT_STATUS_DATA, split: false },
      { label: 'Conducted at (optional)', key: 'CONDUCT_AT', type: "select-child", options: [], split: false },
    ]
  }

};

export const DOCUMENT_FIELDS = Object.freeze({
  TRAVEL: {
     PASS:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Passport number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
      ],
      SBOOK:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Seaman's document type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Document number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
      VISA:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Visa type',key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Visa entry',key:'COMMENTS',type:"string",split:true,postion:0,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa duration',key:'COMMENTS',type:"string",split:true,postion:1,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa number',key:'DOC_NO',type:"string",split:false},
        {label:'Visa country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued country',key:'VISADOC_COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
      ],
      VACC:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Vaccination type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Comment',key:'COMMENTS',type:"string",split:false,exclude:['VACCYF']},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
  },
  MEDICAL:{
    PREEMPL:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1},
    ],
    DRUGALCO:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Positve Negative',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
    ],
    SUPPMED:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1}
    ]
  },
  LICENSES: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Issued at',key:'ISSUE_AT_NAME',type:"string",split:false},
    {label:'Issued by',key:'ISSUE_BY',type:"string",split:false},
    {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
  ],
  TRAINING: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Start date',key:'START_DATE',type:"date",split:false},
    {label:'End date',key:'END_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
    {label:'Internal or external',key:'INTERNAL_NAME',type:"string",split:false},
    {label:'Conducted at',key:'CONDUCT_NAME',type:"string",split:false},
  ]
})

export const ISSUE_LIST = Object.freeze([
  { id: 'poor_quality_scan', value: 'Poor quality of the scan', name: 'Poor quality of the scan' },
  { id: 'does_not_match_document_type', value: 'The scan does not match the document type', name: 'The scan does not match the document type' },
  { id: 'others_contact_office', value: 'Others - please contact the office', name: 'Others - please contact the office' }
]);
export const CASH_ADVANCES_ISSUE_LIST = Object.freeze([
  { id: 'peme_not_passed', value: 'PEME not passed', name: 'PEME not passed' },
  { id: 'training_clearance_not_issued', value: 'Training clearance not issued', name: 'Training clearance not issued' },
  { id: 'poea_contract_not_signed', value: 'POEA contract not signed', name: 'POEA contract not signed' },
  { id: 'awaiting_documents_to_be_submitted_to_SNPI', value: 'Awaiting documents to be submitted to SNPI', name: 'Awaiting documents to be submitted to SNPI' },
  { id: 'automatically_rejected', value: 'Automatically rejected', name: 'Automatically rejected' }
]);

export const BENEFICIARIES_ISSUE_LIST = Object.freeze([
  { id: 'input_vs_attachment_mismatch', value: 'Input vs attachment mismatch', name: 'Input vs attachment mismatch' },
  { id: 'incorrect_account_number', value: 'Card number was provided instead of account number', name: 'Card number was provided instead of account number' },
  { id: 'not_readable_attachment', value: 'Not readable/ visible handwriting/ attachment', name: 'Not readable/ visible handwriting/ attachment' },
  { id: 'usd_bank_account', value: 'USD bank account', name: 'USD bank account' },
  { id: 'incorrect_account_numbers', value: 'Incorrect account numbers', name: 'Incorrect account numbers' },
  { id: 'incorrect_incomplete_attachment_provided', value: 'Incorrect / incomplete attachment provided', name: 'Incorrect / incomplete attachment provided' },
  { id: 'duplicate_request', value: 'Duplicate request', name: 'Duplicate request' },
  { id: 'others_contact_office', value: 'Others', name: 'Others' },
]);

export const EXPENSE_ISSUE_LIST = Object.freeze([
  { id: 'expenses_not_covered', value: 'Expenses not covered', name: 'Expenses not covered' },
  { id: 'bad_quality_scan', value: 'Bad quality of the scan (please resubmit)', name: 'Bad quality of the scan (please resubmit)' },
  { id: 'other_reason', value: 'Other reason', name: 'Other reason' }
]);


export const ADD_EXPENSE_FIELDS: any = {
  SIGN_ON_OFF: {
    PUBLIC_TRANSPORTATION:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child"},
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'},
    ],
    RENTAL_CAR:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE_PRIVATE_CAR:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'KILLOMETER',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: false, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    TRANSPORTATION:[
        { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
         { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
        { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
        { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
        { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
        { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
        { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
        { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
       { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    VISA_FEE:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
         { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
        { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
        { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
       { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
        { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
        { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
        { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
       { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    ADDITIONAL_VISA_COSTS:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
      { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
      ],
    TRAVELLING_ALLOWANCE:[
    { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
     { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
    { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
    { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
    { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
    { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
    { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
    { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
    { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    FAMILY_TRAVEL_ALLOWANCE:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    ACCOMMODATION:[
      { label: 'Ship', required: true, key: 'SHIP',type: "select-child" },
      { label: 'Accommodation days', required: true, key: 'ACCCOM_DAYS', errorType:'ACCCOM_DAYS', type: "string", pattern:"^[0-9]{0,3}$"},
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MEALS:[
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
  },
  MEDICAL_SAFETY: {
    PUBLIC_TRANSPORTATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'KILLOMETER',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    RENTAL_CAR: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    ANNUAL_MEDICAL: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    DA: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    BLOOD_TEST: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRANSPORTATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    VACCINATION: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    SAFETY_SHOES: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ]
  },
  MEDICAL_CLAIM: {
    MEDICAL_CLAIM_REIMBURSEMENT: [
      { label: 'Ship', required: true, key: 'SHIP', type: "select-child",displayKey: 'SHIP_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Injury', required: false,key: 'INJURY', type: "string",maxLength:'15',errorType:'special',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Injury date',required: false, key: 'INJURY_DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,5}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
  },
  TRAINING: {
    MEALS: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    PUBLIC_TRANSPORTATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    MILEAGE_PRIVATE_CAR: [
       { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Kilometers', required: false, key: 'KILOMETER', type: "string",errorType:'KILLOMETER',pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    RENTAL_CAR: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
       { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    ACCOMMODATION: [
      { label: 'Accommodation days', required: true, key: 'ACCCOM_DAYS', errorType:'ACCCOM_DAYS', type: "string", pattern:"^[0-9]{0,3}$"},
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
      { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    VISA_FEE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    EXAMINATION_FEE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAVELLING_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRANSPORTATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    ADDITIONAL_VISA_COSTS: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}
    ],
    FAMILY_TRAVEL_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAINING_ALLOWANCE: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    VACCINATION: [
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ],
    TRAINING_COURSE_FEE: [
      { label: 'Training course', required: true, key: 'TRAINING_COURSE_FEE',type: "select-child",displayKey: 'TRAINING_COURSE_FEE_NAME' },
      { label: 'Date of voucher', required: true, key: 'DATE', type: "date",pattern:/^(0[1-9]|[12]\d|3[01])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/},
      { label: 'Voucher name/ no.', required: false, key: 'VOUCHER_NAME', errorType:'special', type: "string",maxLength:'15',pattern:"^[a-zA-Z0-9]*$"},
      { label: 'Local currency', required: true, key: 'LOCAL_CURRENCY', type: "select-child" },
     { label: 'Amount in local currency',required: true,key:'AMOUNT', errorType:'percentage',type:"string", helperText:"The max amount limit is",pattern:"^[0-9]{0,4}(([\\.]{1}[0-9]{0,2})|())$"},
      { label: 'Amount in USD', required: false, key: 'AMOUNT_IN_USD', type: "label-string"},
      { label: 'Upload document(s)', required: true, key: 'FILES', type: "upload"},
      { label: 'Reason for exceeding amount', required: false, key: 'REASON_FOR_EXCEEDING', type: "textarea",maxLength:'250'},
     { label: 'Description of expense', required: true, key: 'DESCRIPTION', type: "textarea",maxLength:'150'}

    ]
  }

};

export const ADD_FEEDBACK_SURVEY_FIELDS: any = {
  SATISFACTION_SCALE: {
    QUESTION: [
      //{ label: 'Question',required: true, key: 'QUESTION', type: "string", split: false,text:'How would you rate your overall experience on boar' },
      { label: 'Icon1',required: true, key: 'ICON1', type: "image", split: false},
      { label: 'Icon2',required: true, key: 'ICON2', type: "image", split: false},
      { label: 'Icon3',required: true, key: 'ICON3', type: "image", split: false},
      { label: 'Icon4',required: false, key: 'ICON4', type: "image", split: false},
      { label: 'Icon5',required: false, key: 'ICON5', type: "image", split: false},
      //{ label: 'Mandatory',required: true, key: 'MANDATORY', type: "switch", split: false},
    ]
  },
  AGREE_DISAGREE_SCALE: {
    QUESTION: [
      //{ label: 'Question',required: true, key: 'QUESTION', type: "string", split: false,text:'The leadership on board provided clear and consistent direction throughout the voyage.' },
      { label: 'Option 1',required: true, key: 'OPTIONA', type: "string", split: false,maxLength:'25' ,errorType:'special'},
      { label: 'Option 2',required: true, key: 'OPTIONB', type: "string", split: false,maxLength:'25' ,errorType:'special'},
      { label: 'Option 3',required: true, key: 'OPTIONC', type: "string", split: false,maxLength:'25' ,errorType:'special'},
      { label: 'Option 4',required: false, key: 'OPTIOND', type: "string", split: false,maxLength:'25' ,errorType:'special'},
      { label: 'Option 5',required: false, key: 'OPTIONE', type: "string", split: false,maxLength:'25', errorType:'special'},
      //{ label: 'Mandatory',required: true, key: 'MANDATORY', type: "switch", split: false},
    ]
  },
  SINGLE_CHOICE: {
    QUESTION: [
      //{ label: 'Question',required: true, key: 'QUESTION', type: "string", split: false,text:'Did you feel adequately supported in your role?' },
      { label: 'Yes',required: true, key: 'YES', type: "switch", split: false },
      { label: 'No',required: true, key: 'NO', type: "switch", split: false},
      //{ label: 'Mandatory',required: true, key: 'MANDATORY', type: "switch", split: false},
    ]
  },
  FREE_TEXT: {
    QUESTION: [
      //{ label: 'Question',required: true, key: 'QUESTION', type: "string", split: false,text:'What challenges did you face during your tenure, and how could they be addressed?' },
      //{ label: 'Answer',required: true, key: 'ANSWER', type: "string", split: false },
      //{ label: 'Mandatory',required: true, key: 'MANDATORY', type: "switch", split: false},
    ]
  },
  FREE_TEXT_SUGGESTION: {
    QUESTION: [
      //{ label: 'Question',required: true, key: 'QUESTION', type: "string", split: false,text:'Any other comments or suggestions?' },
      //{ label: 'Answer',required: true, key: 'ANSWER', type: "string-answer", split: false },
      //{ label: 'Mandatory',required: true, key: 'MANDATORY', type: "switch", split: false},
    ]
  }

};
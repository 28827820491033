import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '@environments';
 
import { filter, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService, CrewSelfService, StoltApiService } from '@services';
import { APPROVALS_ROLES_MODULES, SETTINGS_ROLES_MODULES } from '@constants';
import { ExitConfirmationModalComponent } from './shared/components/exit-confirmation-modal/exit-confirmation-modal.component';
type ProfileType = {
	givenName?: string,
	surname?: string,
	displayName?: string,
	userPrincipalName?: string,
	id?: string
  };

@Component({
    selector: 'app-root',
    imports: [RouterModule,ExitConfirmationModalComponent],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	title = 'crew-manager-app';
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();
	constructor(
		private readonly broadcastService: MsalBroadcastService,
		private readonly msalService: MsalService,
		private readonly http: HttpClient,
		private readonly authService:AuthService,
		private readonly crewSelfService:CrewSelfService,
		private readonly stoltApiService: StoltApiService,
		private readonly router: Router
	){
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				if(this.msalService.instance.getAllAccounts().length > 0){
					this.checkAndSetActiveAccount();
				}
			}
		})
	}
	checkAndSetActiveAccount() {
		let activeAccount = this.msalService.instance.getActiveAccount();
		if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
		let accounts = this.msalService.instance.getAllAccounts();
		this.msalService.instance.setActiveAccount(accounts[0]);
		}
	}
	ngOnInit(){
		this.broadcastService.inProgress$
		.pipe(
		  filter((status: InteractionStatus) => status === InteractionStatus.None),
		  takeUntil(this._destroying$)
		)
		.subscribe(() => {
		  this.setLoginDisplay();
		  this.getProfileAndUserDetail();
		})
	}
  
	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
	}
	getProfile() {
		this.http.get(environment.AAD_GRAPH_ENDPOINT)
		  .subscribe((profile:ProfileType) => {
			this.authService.setUserAuthData({user:profile});
		});
	}

	getProfileAndUserDetail() {
		this.http.get<ProfileType>(environment.AAD_GRAPH_ENDPOINT)
		  .pipe(
			tap((profile: ProfileType) => {
			  this.authService.setUserAuthData({ user: profile }); // Process profile
			}),
			switchMap(() => this.stoltApiService.getUserDetail()) // Chain to next API
		  )
		  .subscribe({
			next: (response) => {
			  this.crewSelfService.setUserAccessData(response.data);
			  if (response.status === 'success') {
				const role = response?.data?.role || 1;
				type RoleKey = keyof typeof APPROVALS_ROLES_MODULES;
				type SettingRoleKey = keyof typeof SETTINGS_ROLES_MODULES;
				const tempSettingRole = (role === 1 || role === 2)? role : 1;
				const accessKey = `ROLE_${role}` as RoleKey;
				const settingKey = `ROLE_${tempSettingRole}` as SettingRoleKey;
				const access_module = accessKey in APPROVALS_ROLES_MODULES ? APPROVALS_ROLES_MODULES[accessKey] : [];
				const setting_modules = settingKey in SETTINGS_ROLES_MODULES ? SETTINGS_ROLES_MODULES[settingKey] : [];
				this.authService.setUserAuthData({ role: role, modules: access_module, setting_modules:setting_modules});
			  }
			},
			error: (err: any) => {
			  console.error('Error fetching user detail:', err);
			}
		  });
	}
}
